<template>
  <div
    style="height: inherit"
    :style="{ 'background-image': 'url(' + require('@/assets/images/banner/portada_cannagods.png') + ')', 'background-repeat-y':'no-repeat'}"
  >
    <!-- EProject Header -->
    <section id="ecommerce-header">
      <div
        class="row"
      >
        <div class="col-sm-12">
          <h3
            class="filter-title mt-2 ml-2"
            style="color:white"
          >
            Marketplace
          </h3>
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div
                class="search-results ml-2"
                style="color:white"
              >
                {{ totalProjects }} results found
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <!--<b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="mr-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="project in projects"
        :key="project.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <div
            class="img-container"
            style="width: 100%;"
          >
            <b-img
              :src="project.srcImg"
              :alt="`Image of ${project.name}`"
              class="product-img"
              style="max-width:200px"
            />
          </div>
        </div>

        <!-- project Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body"
            >
              {{ project.name }}
            </b-link>
            <br>
            <b-card-text class="item-company">
              By <b-link class="ml-25">
                {{ project.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text
            class="item-description"
            style="white-space: normal;"
          >
            {{ project.description }}
            <br>{{ project.description2 }}
            <br>
            <ul class="product-features list-unstyled">
              <li v-show="project.imageName=='manasr' || project.imageName=='hassnarino'">
                <feather-icon icon="CheckIcon" />&nbsp;
                <span>Presenting this token you can access cannabis services!</span>
              </li>
              <li v-show="project.imageName=='manasr'">
                <feather-icon icon="CheckIcon" />&nbsp;
                <span>With this token you can access different areas of the Cannaverso indigenous academy</span>
              </li>
            </ul>
          </b-card-text>
        </b-card-body>

        <!-- project Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="{ name: 'e-project-product-details', params: { slug: project._id.toString() } }"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>More Information</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProjects"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BImg, BFormRadio, BRow, BCol, BCard, BCardBody, BLink, BCardText, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './EProjectShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useEProjectShop'
// eslint-disable-next-line import/no-cycle
import { useEcommerceUi } from '../useEProject'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    // BDropdown,
    // BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormInput,
    BCard,
    BCardBody,
    BLink,
    BCardText,
    BButton,
    BPagination,
    BImg,

    // SFC
    ShopLeftFilterSidebar,
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProjects,
    } = useShopUi()

    const { projects, fetchProjects } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProjects` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProjects({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          projects.value = response.data
          totalProjects.value = response.data.length
        })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProjects,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      projects,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
